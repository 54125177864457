<template>
  <div class="item" v-if="list.length">
    <span>{{ name }}：</span>
    <div>
      <div class="down">
        <font>展开</font><span class="el-icon-caret-bottom"></span>
      </div>
      <div class="item-list-main">
        <template v-for="item in list">
          <span
            :class="[item.dictValue == active ? 'on' : '']"
            :key="item.id"
            v-if="item.name"
            @click="change(item)"
          >
            {{ item.name }}
          </span>
          <span
            :class="[item.dictValue == active ? 'on' : '']"
            :key="item.dictValue"
            v-if="item.dictLabel"
            @click="change(item)"
          >
            {{ item.dictLabel }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    active: {
      default: "-1",
      type: String | Number,
    },
    change: {
      default() {},
      type: Function,
    },
    list: {
      default: [],
      type: Array,
    },
    name: {
      default: "",
      type: String,
    },
  },
};
</script>